import React from 'react'
import Layout from '../../components/layout'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChrome, faFirefox, faEdge, faSafari, faOpera,} from '@fortawesome/free-brands-svg-icons'

import rexx from '../../images/info/rexx.jpg'
import sage from '../../images/info/sage.png'
import sap from '../../images/info/sap.jpg'
import peoplesoft from '../../images/info/peoplesoft.png'
import Workday from '../../images/info/Workday.jpg'
import word from '../../images/info/word.png'
import officeatwork from '../../images/info/officeatwork.png'

const Technik = () => (
  <Layout>
    <section id="news" className="section-wrapper">
      <header className="section-header">
        <h3>Die Technik</h3>
      </header>
      <p>
        Für technische Detailinformationen kontaktieren Sie uns ganz unverbindlich.
      </p>
    </section>
    <section className="background-pastel-yellow">
      <div className="section-wrapper">
        <h3 className="underlined-title">Webtechnologie</h3>
        <p>
          Für ZEP muss keine Software auf einem Client installiert werden. ZEP ist vollständig via Browser nutzbar. ZEP funktioniert mit den aktuellen Versionen von Microsoft Edge, Firefox, Chrome, Opera und Safari.
          Als Webserver können Apache Tomcat sowie auch Microsoft IIS eingesetzt werden.
        </p>
        <div className="icon-grid">
          <FontAwesomeIcon icon={faEdge} size="3x" color="deepskyblue" />
          <FontAwesomeIcon icon={faFirefox} size="3x" color="orange" />
          <FontAwesomeIcon icon={faChrome} size="3x" color="mediumseagreen" />
          <FontAwesomeIcon icon={faOpera} size="3x" color="red" />
          <FontAwesomeIcon icon={faSafari} size="3x" color="darkslategrey" />
        </div>
      </div>
    </section>
    <section >
      <div className="section-wrapper">
        <h3 className="underlined-title">Schnittstellen</h3>
        <p>
            ZEP kann via File-Schnittstelle auf die Mitarbeitendendaten Ihres Unternehmens zugreifen. Vorhandene, aktuelle Mitarbeiterdaten wie z.B. Heimatort werden so in die Zeugnisse eingefügt.
        </p>
        <div className="picture-grid">
          <img src={sage} alt="Sage" height="50" />
          <img src={sap} alt="SAP" height="50" />
          <img src={peoplesoft} alt="Peoplesoft" height="50" />
          <img src={rexx} alt="RexX" height="50" />
          <img src={Workday} alt="workDay" height="50" />
        </div>
      </div>
    </section>
    <section className="background-pastel-blue">
      <div className="section-wrapper">
        <h3 className="underlined-title">Dokumentformate</h3>
        <p>ZEP kann Zeugnisse in den Formaten DOC und RTF generieren. officeatwork lässt sich ebenfalls einbinden. Weitere Formate und Systemintegrationen sind jederzeit möglich.</p>
        <div className="picture-grid">
          <img src={officeatwork} alt="OfficeAtWork" />
          <img src={word} alt="Word" />
        </div>
      </div>
    </section>
  </Layout >
)

export default Technik
